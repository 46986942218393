<template>
  <a-modal
    :visible="visible"
    :title="title"
    :maskClosable="false"
    :closable="!loading"
    :confirmLoading="loading"
    :cancel-button-props="{ disabled: loading }"
    width="750px"
    @ok="handleOk"
    @cancel="cancel"
    okText="保存"
    cancelText="取消"
    destroyOnClose
  >
    <a-form
      ref="formRef"
      :model="formState"
      autocomplete="off"
      v-bind="formItemLayout"
    >
      <a-row :gutter="12">
        <a-col :span="12">
          <a-form-item
            label="设备厂家"
            name="equipProducerId"
            :rules="[{ required: true, message: '请选择设备厂家' }]"
          >
            <a-select
              class="input"
              v-model:value="formState.equipProducerId"
              placeholder="请选择设备厂家"
              :options="optionsData.equipOpt"
              :maxlength="20"
              :disabled="optionsData.equipLoading"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="设备名称"
            name="equipName"
            :rules="[{ required: true, message: '请输入设备名称' }]"
          >
            <a-input
              class="input"
              v-model:value="formState.equipName"
              placeholder="请输入设备名称"
              :maxlength="20"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            label="设备SN"
            name="equipSn"
            :rules="[
              { required: true, message: '设备SN不能为空' },
              { pattern: /^[^\s]*$/, message: '禁止输入空格' },
            ]"
          >
            <a-input
              class="input"
              v-model:value="formState.equipSn"
              :maxlength="50"
              placeholder="请输入设备SN"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="设备到期时间"
            name="equipExpireDate"
            :rules="[{ required: true, message: '请选择设备到期时间' }]"
          >
            <a-date-picker
              class="input"
              v-model:value="formState.equipExpireDate"
              placeholder="请选择设备到期时间"
              format="YYYY-MM-DD HH:mm:ss"
              value-format="YYYY-MM-DD HH:mm:ss"
              :show-time="{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="摄像头厂家"
            name="cameraProducerId"
            :rules="[{ required: true, message: '请选择摄像头厂家' }]"
          >
            <a-select
              class="input"
              v-model:value="formState.cameraProducerId"
              placeholder="请选择摄像头厂家"
              :options="optionsData.cameraOpt"
              :disabled="optionsData.cameraLoading"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="摄像头SN"
            name="cameraSn"
            :rules="[
              { required: true, message: '摄像头SN不能为空' },
              { pattern: /^[^\s]*$/, message: '禁止输入空格' },
            ]"
          >
            <a-input
              class="input"
              v-model:value="formState.cameraSn"
              :maxlength="50"
              placeholder="请输入摄像头SN"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="SIM卡号"
            name="simCode"
            :rules="[
              { required: true, message: 'SIM卡号不能为空' },
              { pattern: /^[^\s]*$/, message: '禁止输入空格' },
            ]"
          >
            <a-input
              class="input"
              v-model:value="formState.simCode"
              :maxlength="50"
              placeholder="请输入SIM卡号"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="SIM到期时间"
            name="simExpireDate"
            :rules="[{ required: true, message: '请选择SIM到期时间' }]"
          >
            <a-date-picker
              class="input"
              v-model:value="formState.simExpireDate"
              :maxlength="255"
              format="YYYY-MM-DD HH:mm:ss"
              value-format="YYYY-MM-DD HH:mm:ss"
              placeholder="请选择SIM到期时间"
              :show-time="{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="车辆信息"
            name="carId"
            :rules="[{ required: true, message: '请选择车辆信息' }]"
          >
            <a-select
              class="input"
              v-model:value="formState.carId"
              placeholder="请选择车辆信息"
              :options="optionsData.vehicelOpt"
              :disabled="optionsData.vehicelLoading"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item name="status" label="状态" required>
            <a-switch
              v-model:checked="formState.status"
              checkedValue="1"
              unCheckedValue="0"
            />
          </a-form-item>
        </a-col>
        <h3>添加剂设置</h3>
        <a-col :span="24">
          <div
            style="
              max-height: 130px;
              overflow-x: hidden;
              overflow-y: auto;
              margin-bottom: 10px;
            "
          >
            <a-row v-for="(item, index) in formState.potList" :key="index">
              <a-col :span="4">
                <a-form-item>
                  <a-input
                    class="input"
                    addon-before="序号"
                    :value="index + 1"
                    disabled
                  />
                </a-form-item>
              </a-col>
              <a-col :span="7">
                <a-form-item
                  :name="['potList', index, 'additiveName']"
                  required
                  :rules="[
                    { required: true, message: '名称不能为空' },
                    { pattern: /^[^\s]*$/, message: '禁止输入空格' },
                  ]"
                >
                  <a-input
                    class="input"
                    addon-before="名称"
                    v-model:value="item.additiveName"
                    :maxlength="20"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="5">
                <a-form-item :name="['potList', index, 'standard']">
                  <a-input
                    class="input"
                    addon-before="规格"
                    v-model:value="item.standard"
                    :maxlength="20"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item
                  :name="['potList', index, 'volume']"
                  :rules="[
                    { required: true, message: '容量不能为空' },
                    { pattern: /^[^\s]*$/, message: '禁止输入空格' },
                  ]"
                >
                  <a-input
                    class="input"
                    addon-before="容量(KG)"
                    v-model:value="item.volume"
                    :maxlength="10"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="2">
                <a-button
                  type="link"
                  danger
                  @click="del(item)"
                  :disabled="formState.potList.length < 2"
                >
                  <delete-outlined />
                </a-button>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24">
          <a-button
            type="primary"
            block
            @click="plus()"
            :disabled="formState.potList.length >= 4"
          >
            <plus-outlined />增加一行
          </a-button>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script setup>
import dayjs from "dayjs";
import { HANDLE, producerAPI, equipAPI } from "@/Api/index.js";
import { ref, defineEmits, defineExpose, onMounted, reactive } from "vue";
import { useUserStore } from "@/store/user.js";
const { store } = useUserStore();
const emits = defineEmits(["update:value", "reload"]);
const formRef = ref(null);
const loading = ref(false);
const isEdit = ref(false);
const title = ref("新增");
const visible = ref(false);
const formState = ref({
  account: undefined,
  linkman: undefined,
  password: undefined,
  equipSn: undefined,
  producerName: undefined,
  status: undefined,
  telphone: undefined,
  type: undefined,
  email: undefined,
  remarks: undefined,
});

const open = (data) => {
  const id = data && data.id;
  formState.value = {
    equipProducerId: undefined,
    equipProducerName: undefined,
    equipName: undefined,
    equipSn: undefined,
    equipExpireDate: undefined,
    cameraProducerId: undefined,
    cameraProducerName: undefined,
    cameraSn: undefined,
    simCode: undefined,
    simExpireDate: undefined,
    carId: undefined,
    vehicelNo: undefined,
    status: "1",
    potList: [
      {
        additiveName: undefined,
        standard: undefined,
        volume: undefined,
        sortNumber: undefined,
      },
    ],
  };
  visible.value = true;
  if (id) {
    isEdit.value = true;
    title.value = "编辑";
    const compid = store.compid;
    equipAPI
      .edit({ compid, id })
      .then(({ data }) => {
        formState.value = data;
      })
      .catch(HANDLE.handleErrorTag("获取信息失败"));
  } else {
    isEdit.value = false;
    title.value = "新增";
  }
};

const formItemLayout = {
  // labelCol: { span: 7 },
  labelCol: { style: { width: "120px" } },
};

const cancel = () => {
  formRef.value.resetFields();
  visible.value = false;
};

const finish = (res) => {
  formRef.value.resetFields();
  visible.value = false;
  emits("reload");
  return res;
};

const handleOk = async () => {
  if (loading.value) return;
  await formRef.value.validateFields();
  loading.value = true;

  const respData = { ...formState.value, compid: store.compid };
  // 补充摄像头厂家名
  if (respData.cameraProducerId) {
    const opt = optionsData.cameraOpt.find(
      ({ value }) => value === respData.cameraProducerId
    );
    if (opt) {
      respData.cameraProducerName = opt.label;
    }
  }
  // 补充设备厂家名
  if (respData.equipProducerId) {
    const opt = optionsData.equipOpt.find(
      ({ value }) => value === respData.equipProducerId
    );
    if (opt) {
      respData.equipProducerName = opt.label;
    }
  }
  // 补充车牌号
  if (respData.carId) {
    const opt = optionsData.vehicelOpt.find(
      ({ value }) => value === respData.carId
    );
    if (opt) {
      respData.vehicelNo = opt.label;
    }
  }
  // 补充sortNumber
  respData.potList = respData.potList.map((item, index) => ({
    ...item,
    sortNumber: index + 1,
  }));

  equipAPI
    .save({ ...respData })
    .then(HANDLE.handlerSuccessMessage("保存成功"))
    .then(finish)
    .catch(HANDLE.handleErrorTag("保存失败"))
    .finally(() => {
      loading.value = false;
    });
};

const del = (item) => {
  if (formState.value.potList.length > 1) {
    formState.value = {
      ...formState.value,
      potList: formState.value.potList.filter((a) => a != item),
    };
  }
};

const plus = () => {
  console.log("plus");
  formState.value = {
    ...formState.value,
    potList: [
      ...formState.value.potList,
      {
        additiveName: undefined,
        standard: undefined,
        volume: undefined,
      },
    ],
  };
};

const optionsData = reactive({
  // 设备厂家列表
  equipProducers: [],
  equipLoading: true,
  equipOpt: [],
  // 摄像头厂家列表
  cameraProducers: [],
  cameraLoading: true,
  cameraOpt: [],
  // 车辆信息列表
  vehicels: [],
  vehicelLoading: true,
  vehicelOpt: [],
});

// 设备厂家列表
const initEquipProducers = () => {
  optionsData.equipLoading = true;
  producerAPI
    .selProducerList({ type: "1" })
    .then(({ data }) => {
      // {carId: '002', vehicelNo: '豫A55844'}
      optionsData.equipProducers = data;
      optionsData.equipOpt = data.map(({ id: value, producerName: label }) => ({
        label,
        value: `${value}`,
      }));
    })
    .catch(HANDLE.handleErrorTag("获取设备厂家列表失败"))
    .finally(() => {
      optionsData.equipLoading = false;
    });
};

// 摄像头厂家列表
const initCameraProducers = () => {
  optionsData.cameraLoading = true;
  producerAPI
    .selProducerList({ type: "2" })
    .then(({ data }) => {
      optionsData.cameraProducers = data;
      optionsData.cameraOpt = data.map(
        ({ id: value, producerName: label }) => ({
          label,
          value: `${value}`,
        })
      );
    })
    .catch(HANDLE.handleErrorTag("获取摄像头厂家列表失败"))
    .finally(() => {
      optionsData.cameraLoading = false;
    });
};

// 车辆信息列表
const initVehicels = () => {
  optionsData.vehicelLoading = true;
  const compid = store.compid;
  equipAPI
    .selVehicelList({ compid })
    .then(({ data }) => {
      optionsData.vehicels = data;
      optionsData.vehicelOpt = data.map(
        ({ carId: value, vehicelNo: label }) => ({
          label,
          value,
        })
      );
    })
    .catch(HANDLE.handleErrorTag("获取车辆信息列表失败"))
    .finally(() => {
      optionsData.vehicelLoading = false;
    });
};

onMounted(() => {
  initEquipProducers();
  initCameraProducers();
  initVehicels();
});

defineExpose({
  open,
});
</script>

<style scoped>
.input {
  width: 100%;
}
</style>
