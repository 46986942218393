import { Tag, Button, Space, Popconfirm } from "ant-design-vue";
export default function ({ changeStatus, recharge, addEditor }) {
  return [
    {
      title: "所属租户/企业",
      dataIndex: "tenantName",
      width: 140,
      ellipsis: true,
    },
    {
      title: "厂家名称",
      dataIndex: "equipProducerName",
      width: 80,
      ellipsis: true,
    },
    {
      title: "设备名称",
      dataIndex: "equipName",
      width: 80,
      ellipsis: true,
    },
    {
      title: "设备SN",
      dataIndex: "equipSn",
      width: 120,
      ellipsis: true,
    },
    {
      title: "设备到期时间",
      dataIndex: "equipExpireDate",
      width: 180,
      ellipsis: true,
    },
    {
      title: "摄像头SN",
      dataIndex: "cameraSn",
      width: 120,
      ellipsis: true,
    },
    {
      title: "SIM卡号",
      dataIndex: "simCode",
      width: 120,
      ellipsis: true,
    },
    {
      title: "SIM到期时间",
      dataIndex: "simExpireDate",
      width: 180,
      ellipsis: true,
    },
    {
      title: "车号",
      dataIndex: "carId",
      width: 80,
      ellipsis: true,
    },
    {
      title: "车牌号",
      dataIndex: "vehicelNo",
      width: 100,
      ellipsis: true,
    },
    {
      title: "状态",
      dataIndex: "status",
      width: 80,
      fixed: "right",
      customRender: ({ text }) => {
        return {
          1: <Tag color="green">启用</Tag>,
          0: <Tag color="red">禁用</Tag>,
        }[text];
      },
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: "180px",
      align: "center",
      fixed: "right",
      customRender: ({ record }) => {
        return (
          <Space>
            <Button size="small" type="link" onClick={() => addEditor(record)}>
              编辑
            </Button>
            {/* <Button size="small" type="link" onClick={() => recharge(record)}>
              缴费
            </Button> */}
            <Popconfirm
              title={`确定要${{ 0: "启用", 1: "禁用" }[record.status]}么？`}
              cancelText="取消"
              okText="确定"
              onConfirm={() => changeStatus(record)}
            >
              <Button
                size="small"
                type="link"
                danger={{ 0: false, 1: true }[record.status]}
              >
                {{ 0: "启用", 1: "禁用" }[record.status]}
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
}
